// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screen5-container{
    width: 100vw;
    height: 100vh;
    background-color: rgb(175, 254, 222);
    border-top: 5px solid black;
    display: grid;
    justify-items: center;
    grid-template-rows: 1fr 1fr 1fr;
    overflow: auto;
}

.meeting-grid{
    display: flexbox;
    justify-items: center;
    align-self: center;
    gap: 0rem;
    margin: auto;
    width: 100%;
    text-align: center;
}

.meeting-details-container{
    background-color: rgba(0, 0, 0, 0.079);
    border-radius: 2rem;
    padding: 2rem;
    width: 60%;
}


.meeting-grid p{
    font-size: 2rem;
    font-size: "Baloo 2", cursive;
    text-align: center;
}

.meeting-location{
    display: grid;
    align-items: center;
    justify-items: center;
    font-family: "Baloo 2", cursive;
    font-size: 3rem;
}


@media screen and (max-width: 1000px){
    .meeting-grid p{
        font-size: 1.5rem;
    }

    .meeting-location{
        font-size: 2rem;
    }

}

@media screen and (max-width: 640px){
    .meeting-grid p{
        font-size: 0.8rem;
    }

    .meeting-location{
        font-size: 1rem;
    }

}`, "",{"version":3,"sources":["webpack://./src/screen5/screen5.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,2BAA2B;IAC3B,aAAa;IACb,qBAAqB;IACrB,+BAA+B;IAC/B,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;IACtC,mBAAmB;IACnB,aAAa;IACb,UAAU;AACd;;;AAGA;IACI,eAAe;IACf,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,+BAA+B;IAC/B,eAAe;AACnB;;;AAGA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,eAAe;IACnB;;AAEJ;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".screen5-container{\n    width: 100vw;\n    height: 100vh;\n    background-color: rgb(175, 254, 222);\n    border-top: 5px solid black;\n    display: grid;\n    justify-items: center;\n    grid-template-rows: 1fr 1fr 1fr;\n    overflow: auto;\n}\n\n.meeting-grid{\n    display: flexbox;\n    justify-items: center;\n    align-self: center;\n    gap: 0rem;\n    margin: auto;\n    width: 100%;\n    text-align: center;\n}\n\n.meeting-details-container{\n    background-color: rgba(0, 0, 0, 0.079);\n    border-radius: 2rem;\n    padding: 2rem;\n    width: 60%;\n}\n\n\n.meeting-grid p{\n    font-size: 2rem;\n    font-size: \"Baloo 2\", cursive;\n    text-align: center;\n}\n\n.meeting-location{\n    display: grid;\n    align-items: center;\n    justify-items: center;\n    font-family: \"Baloo 2\", cursive;\n    font-size: 3rem;\n}\n\n\n@media screen and (max-width: 1000px){\n    .meeting-grid p{\n        font-size: 1.5rem;\n    }\n\n    .meeting-location{\n        font-size: 2rem;\n    }\n\n}\n\n@media screen and (max-width: 640px){\n    .meeting-grid p{\n        font-size: 0.8rem;\n    }\n\n    .meeting-location{\n        font-size: 1rem;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
